<template>
  <div class="page-wrapper">
    <Navbar class="navbar"/>
    <div class="landing-page">
      <p class="sponsored-by">Business Support Tools. Sponsored by</p>
      <div class="header-container">
        <img class="natwest-logo" alt="Logo" :src="this.getLogo()" />
        <h1 class="header">Simple,Speedy,Secure</h1>
      </div>
      <div class="grants-container">
        <div class="grants-header">
          <div class="grants-finder">
            <h1 class="grants-finder-title">Grant Finder</h1>
            <h2 class="grants-finder-subtitle">Use the quick and free grant finder tool to find grants relevant to your business. No sign up is required.</h2>
            <router-link :to="{ name: 'natwest-form' }" @click.native="trackFindGrantsCTAEvent" class="btn grants-finder-button">Find Grants</router-link>
          </div>
          <img src="@/assets/images/natwest/man.svg" alt="man" class="grants-image">
        </div>
        <div class="grants-content">
          <div class="grants-sub-section">
            <ul>
              <h3>Innovation Grants </h3>
              <p>Grants for new ideas and innovation</p>
              <li>Innovation programs > 6 months</li>
              <li>Business and research collaborations</li>
              <li>Overcoming technical challenges to commercialisation</li>
            </ul>
          </div>
          <div class="grants-sub-section">
            <ul>
              <h3>Regional Grants </h3>
              <p>Grants for business expansion</p>
              <li>Capital & Revenue grants for expansion</li>
              <li>Energy efficiency grants</li>
              <li>Community/social enterprise</li>
            </ul>
          </div>
          <div class="grants-sub-section">
            <ul>
              <h3>National Grants </h3>
              <p>Grants based on government initiatives</p>
              <li>COVID business interruption</li>
              <li>Business rates relief</li>
              <li>Access to Broadband</li>
            </ul>
          </div>
        </div>
      </div>
      <Footer :natwestFooter="true"/>
    </div>
  </div>
</template>

<script>
import Navbar from './components/Navbar'
import Footer from '@/components/Footer'
import { amplitudeEvents } from '@/utils/constants'
import checkGrantFinderRedirects from './mixins/checkGrantFinderRedirects'

export default {
  name: 'landing',
  mixins: [checkGrantFinderRedirects],
  components: {
    Navbar,
    Footer
  },
  data() {
    return {
      group: 'natwest'
    }
  },
  beforeMount() {
    this.setGroup()
  },
  mounted() {
    this.identifyUser()
    this.trackPageView()
  },
  methods: {
    setGroup() {
      if (this.$route.query.group) {
        this.group = this.$route.query.group
      }
      window.localStorage.setItem('group', this.group)
    },
    getLogo() {
      if (this.group && ['ulster', 'natwest', 'rbs', 'lombard'].includes(this.group)) {
        return require(`../../../app/product/assets/images/partners/${this.group}.png`)
      }
      return require('../../../app/product/assets/images/partners/natwest.png')
    },
    getLogoClass() {
      return {
        [`${this.group}`]: true
      }
    },
    identifyUser() {
      this.$ma.identify({
        group: this.group
      })
    },
    trackPageView() {
      this.$ma.trackEvent({
        eventType: amplitudeEvents.natWestSMEGrants.LANDING_PAGE_VIEW,
        eventProperties: {
          group: this.group
        }
      })
    },
    trackFindGrantsCTAEvent() {
      this.$ma.trackEvent({
        eventType: amplitudeEvents.natWestSMEGrants.LANDING_PAGE_CTA,
        eventProperties: {
          group: this.group,
          clicked: 'Find Grants Clicked'
        }
      })
    },
    trackTaxSavingsCTAEvent() {
      this.$ma.trackEvent({
        eventType: amplitudeEvents.natWestSMEGrants.LANDING_PAGE_CTA,
        eventProperties: {
          group: this.group,
          clicked: 'Calculate Tax Savings Clicked'
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>

@import '@/assets/styles/swoop/_variables.scss';
body {
  background: var(--color-error-50);
  font-family: $font-primary ;
}

.page-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  background: $color-whisper;

  .navbar {
    max-width: 1335px;
  }

  .landing-page {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    max-width: 1335px;
    border-radius: 25px;
    padding: 10px 40px 40px 40px;
    margin: 0px 30px 30px 30px;
    background: $color-white;

    h1 {
      margin: 0;
      font-size: $lh-lg-mobile;
      font-weight: 700;
      line-height: 42px;
      color: $color-jagger;
    }

    h2,b {
      font-size: $font-size-base;
      font-weight: 700;
      color: var(--color-primary-500);
    }

    ul{
      padding-left: 10px;
    }

    .sponsored-by {
      font-size: $font-size-lg;
      font-weight: 550;
      line-height: 21px;
      color:$color-black;
      min-width: 100px;
    }

    .header-container {
      display: flex;
      justify-items: flex-start;
      align-items: center;
      width: 100%;
      margin-bottom: 20px;

      .header {
        text-align: center;
        width: 80%;
        margin-right: 200px;
        color: var(--color-primary-500);
      }
      .natwest-logo {
        width: 210px;
      }
    }

    .grants-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      margin-top: 30px;
      width: 100%;

      .grants-header {
        display: flex;
        justify-content: flex-start;

        .grants-finder {
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          align-items: flex-start;
          padding-right: 20px;

          .grants-finder-title{
            font-size: 32px;
          }

          .grants-finder-subtitle {
            font-size: $font-size-lg-mobile;
            font-weight: 550;
            color: var(--color-primary-500);
          }

          .grants-finder-button {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 55px;
            width: 184px;
            margin: 0px 0px 30px 0px;
            background: var(--color-error-500);
            color: $color-white;

            &:hover {
              cursor: pointer;
              background-color: var(--color-tertiary-400);
              transition: background-color .2s ease-in-out;
            }
          }
        }

        .grants-image {
          width: 150px;
        }
      }

      .grants-content {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
        margin-top: 30px;

        .grants-sub-section{
          padding: 0px 10px 0px 10px;
          margin-bottom: 20px;
          margin-right: 5px;
        }
      }

      .grants-image {
        margin-top: 30px;
      }
    }
  }

  @media only screen and (max-width: 1400px) {
    .landing-page .tax-savings-container {
      .tax-savings-content .tax-savings-sub-section {
        width: 100%;
      }
    }
  }

  @media only screen and (max-width: 950px) {
    .landing-page {
      .header-container {
        flex-wrap: wrap;
        margin: 0;

        .header {
          margin-right: 0;
          text-align: left;
          margin: 30px 0px 30px 0px;
          width: 100%;
        }
      }

      .grants-container {
        width: 100%;
        margin-top: 0;

        .grants-header {
          flex-wrap: wrap;
          justify-content: center;

          .grants-finder {
            width: 100%;
            justify-content: center;
            padding: 0;
          }
        }
      }

      .tax-savings-container {
        width: 100%;

        .tax-savings-title {
          font-size: $font-size-base-mobile;
          line-height: $line-height-base-mobile;
        }
        .tax-savings-subtitle {
          font-size: $fs-lg-mobile;
          line-height: 20px;
        }
        .tax-savings-list-container {
          flex-wrap: wrap;
        }
        .tax-savings-content {
          margin-top: 30px;

          .tax-savings-sub-section {
            width: 100%;
            padding: 0;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 580px) {
    .landing-page .tax-savings-container {
      .tax-savings-button {
        line-height: 20px;
      }
    }
  }

  @media only screen and (max-width: 500px) {
    .landing-page {
      .header-container .natwest-logo {
        width: 150px;
      }

      .header {
        font-size: $fs-lg-mobile;
      }

      .tax-savings-container {
        padding: 5px;
      }

      .grants-container {
        .grants-content .grants-sub-section {
          width: 100%;
        }
      }
    }
  }

  @media only screen and (max-width: 350px) {
    .landing-page {
      padding: 12px;
      margin: 0;
    }
  }
}
</style>
